import Vue from "vue";

/*TITLE*/
document.title = "TIENDAS 8 | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "TIENDAS 8";
Vue.prototype.$subtitle = "9 viviendas con 1, 2, 3 y 4 dormitorios";

/*INTRO*/
Vue.prototype.$promoter = "TIENDAS 8";
Vue.prototype.$introSubtitle = "9 viviendas con 1, 2, 3 y 4 dormitorios";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "0-livingroom--20221124091423.jpeg";
Vue.prototype.$image_kitchen = "0-kitchen--20221124091423.jpeg";
Vue.prototype.$image_bath1 = "0-bathroom1--20221124091423.jpeg";
Vue.prototype.$image_bath2 = "";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "0-livingroom--20221124091423.jpeg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "0-kitchen--20221124091423.jpeg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "0-bathroom1--20221124091423.jpeg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/tiendas8/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = false;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20de%20las%20tiendas%20804003%20almería",
    text: "C. de las Tiendas, 804003 Almería",
  },
];

Vue.prototype.$showroom = [
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:609479676/609463224",
    text: "609479676/609463224",
  },
];
